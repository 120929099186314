/*MUI start */

.options_wrapper .MuiInputBase-input {
  padding: 3px 2px !important;
  color: #333333 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: 'Roboto' !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #2b8dff !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
  background-color: rgb(43, 141, 255, 0.18) !important;
}

.MuiSwitch-track {
  background-color: #cccccc !important;
  opacity: 1 !important;
}

.settings_form .MuiInput-underline:before {
  border-bottom: 1px solid #e6e6e6 !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #2b8dff !important;
}

.MuiInput-underline.Mui-focused:after {
  transform: scaleX(1) !important;
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart {
  width: 100% !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2b8dff !important;
}
/*MUI end*/

.modal_wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}
.settings_form {
  padding: 40px;
  width: 100%;
  max-width: 412px;
  min-height: 100%;
  background-color: #f2f2f2;
  border-radius: 0;
  position: absolute;
  z-index: 31;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 4px 3px 18px -3px rgba(186, 186, 186, 1);
  -moz-box-shadow: 4px 3px 18px -3px rgba(186, 186, 186, 1);
  box-shadow: 4px 3px 18px -3px rgba(186, 186, 186, 1);
  height: 100%;
  overflow: auto;
}
.tittle_style {
  font-family: 'Roboto';
  font-size: 36px;
  color: #000000;
  font-weight: 300;
  margin-bottom: 60px;
}

.credentials_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.credentials_userpwline {
  display: flex;
  flex-direction: column;
}

.credentials_title_line {
  color: black;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transcription_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

/*OPTIONS START*/

.options_wrapper {
  display: flex;
  flex-direction: column;
}

.options_wrapper .MuiInputBase-root {
  margin-bottom: 15px !important;
}
.options_wrapper .MuiInputBase-root input:-webkit-autofill::first-line {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.options_wrapper .MuiInputBase-input {
  text-align: end !important;
  color: #1a1a1a !important;
  padding-right: 25px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.type_number .MuiInputBase-input {
  padding-right: 0 !important;
  color: inherit !important;
}

.options_wrapper .MuiTypography-colorTextSecondary {
  color: #333333 !important;
  font-size: 12px !important;
  font-weight: 300 !important;
}
.options_wrapper .MuiSvgIcon-root {
  height: 24px !important;
  width: 24px !important;
}

.input_adornment p,
.input_adornment .MuiTypography-colorTextSecondary {
  font-size: 14px !important;
  color: #333333 !important;
  margin: 0;
  font-weight: 300 !important;
}

.checkbox_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px;
  font-weight: 300;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.checkbox_wrapper .PrivateSwitchBase-root-59 {
  padding: 0 !important;
}
.checkbox_wrapper .MuiIconButton-root {
  padding: 0 !important;
}

.settings_refresh_button {
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  color: #333333c4 !important;
  cursor: pointer;
  margin-left: 0px;
  padding-bottom: 2px;
}
.settings_refresh_button.clicked {
  transform: rotate(620deg);
  transition: transform 250ms ease-in-out;
}
.settings_refresh_button .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}
/*OPTIONS END*/

.transcription_title_line {
  color: black;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.settings_buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cancel_button {
  color: #707070;
  padding: 0;
  margin: 0;
  background: none;
  font-family: 'Roboto';
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: normal;
  margin-right: 20px;
  transition: all 0.2s ease-in-out;
}
.cancel_button:hover {
  color: #494949;
  transition: all 0.2s ease-in-out;
}
.save_button {
  color: #ffffff;
  background-color: #2b8dff;
  font-family: 'Roboto';
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: bolder;
  padding: 10px 15px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
.save_button:hover {
  background-color: rgb(13, 126, 255);
  transition: all 0.2s ease-in-out;
}

.update_dict_button_disabled {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.update_dict_button {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.update_dict_button {
  color: #707070;
  transition: color 200ms ease-in-out;
}
.update_dict_button.sync {
  color: #2b8dff;
  transition: color 200ms ease-in-out;
}
.update_dict_button:hover {
  color: #ffffff;
  transition: color 200ms ease-in-out;
}
.dict_icon_bg {
  fill: none;
  transition: fill 200ms ease-in-out;
}
.update_dict_button:hover .dict_icon_bg {
  fill: rgb(13, 126, 255);
  transition: fill 200ms ease-in-out;
}
.update_dict_button.sync:hover {
  color: #ffffff;
  transition: color 200ms ease-in-out;
}
.dict_aimation_wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.dict_commands_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.num_of_new_words {
  color: #2b8dff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  padding-top: 2px;
}

@media only screen and (max-width: 400px) {
  .settings_form {
    padding: 30px 15px;
  }
}
