html,
body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100%;
  margin: 0;
}

main {
  height: 100vh;
}

article {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Applied filters */

.applied-filters-row {
  display: flex;

  gap: 1rem;
  padding-top: 20px;
  padding-left: 10px;
}

.applied-filters-inner-wrapper {
  align-items: center;
  gap: 1rem;
  display: flex;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.applied-filter-item {
  flex-shrink: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.322);
  height: 30px;
}

.applied-filter-item > span {
  padding: 0 5px;
}

.applied-filter-remove {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applied-filter-remove:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.applied-filters-row-tag {
  font-family: 'Roboto';
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* ----- Srollbar za filter (zlo redko, ma za vsak slucaj) */

.applied-filters-inner-wrapper::-webkit-scrollbar {
  height: 3px;
  display: block;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-track {
  background: #cccccc;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-thumb {
  background: #005173;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ------------------------------ Pagination */
.pagination-wrapper {
  flex-shrink: 0;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------ Tabela */

.table-container {
  width: 90vw;
  position: relative;
  margin: auto;
}

.table-wrapper {
  align-items: flex-start;
  flex-grow: 1;
  margin: auto;
  margin-top: 35px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  padding-bottom: 10px;

  /* background-color: #5d60ff0a; */
}

.row {
  display: flex;

  width: fit-content;
  min-width: 100%;

  position: relative;

  padding: 5px 0px 5px 15px;

  cursor: pointer;

  background-color: #fafafa;

  border-bottom: 1px solid rgba(146, 146, 146, 0.438);
  border-left: 3px solid #62a9fb00;
  transition: border 60ms ease-out;
  transition: background-color 150ms ease-out;
}

.row:hover {
  background-color: #f2f2f2;
  opacity: 0.9;
}

.row-inner-wrapper {
  min-width: fit-content;
  position: relative;

  overflow-y: hidden;
  height: 28px;
}

.row-details-header {
  position: absolute;
  left: 0%;
  right: 0%;

  top: -100%;

  transition: all 0.15s ease-in-out !important;

  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #457ab6;

  display: flex;
  align-items: center;

  padding-top: 6px;

  opacity: 0;

  height: 100%;

  z-index: 1;
}

.row-details-header.active {
  top: 0%;

  opacity: 1;
}

.row-attribute-wrapper {
  position: relative;

  display: flex;
  opacity: 1;
  transition: all 0.15s ease-in-out !important;
  overflow: hidden;

  min-width: 100%;
  width: fit-content;
}

.row-attribute-wrapper.hidden {
  opacity: 0;
}

.row-extender-icon-spacer {
  width: 40px;
}

.row-extender-icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
}

.row-individual-attribute {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;

  height: 28px;

  line-height: 200%;

  /* position: relative; */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-extender-icon {
  cursor: pointer;
  z-index: -1;
  padding: 3px;
  transform: rotate(270deg);
  transition: all 0.35s ease-in-out !important;
  color: black;
}

.row-extender-icon.extended {
  transform: rotate(360deg);
}

/* When the accordion is opened */
.row-active {
  border-left: 3px solid #62a9fb;
}

.row-attr-labels {
  display: flex;
  align-items: center;
}

.row-attr-labels > * {
  margin-right: 8px !important;
}

/* ------------------------------ Table header */

.header-item-resizable-handle {
  width: 15px;
  height: 100%;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.header-item-resizable-handle:hover {
  opacity: 1;
}

.header-item-resizable-handle > div {
  width: 2px;
  height: 60%;
  background-color: rgba(63, 63, 63, 0.5);
  cursor: col-resize;
}

.header-item-controls {
  min-width: min-content;
  flex-grow: 1;
  display: flex;
  direction: row;
  align-items: center;
}

.table-header {
  display: flex;
  padding: 4px 0;
  padding-left: 18px;
  min-width: 100%;
  width: fit-content;

  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 3;
}

.table-header-item-wrapper {
  display: flex;
  height: 30px;
}

.table-header-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: move;
  position: relative;
  border-bottom: 1px solid #00000000;
}

.table-header-item-dragging {
  border-bottom: 2px solid #fc8831;
  background-color: #fafafa;
  z-index: 33;
}

.table-header-item-over {
  border-bottom: 1px solid rgba(39, 39, 39, 0.1);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.table-header-label {
  height: 24px;
  padding-top: 2px;
  user-select: none;
  margin-right: 3px;

  font: 400 16px 'Roboto';

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-header-icon-filter {
  color: rgba(62, 107, 165, 0.712);
  max-height: 24px;
  margin: 0px;
  padding-bottom: 2px;
}

.table-header-icon {
  color: rgba(0, 0, 0, 0.712);
  cursor: pointer;
  max-height: 24px;
  margin: 0px;
  padding-bottom: 2px;
}

.table-header-icon:hover {
  color: rgba(39, 39, 39, 0.274);
}

/* ------------------------------ Srollbar v Viewer tabeli */

.table-wrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  display: block;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #cccccc;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #62a9fb;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Accordion */

.accordion-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.accordion-header-icon {
  color: rgba(0, 0, 0, 0.63);
}

.accordion-header-icon:hover {
  cursor: pointer;
  color: #2b8dff;
}

.accordion-header-delete-icon:hover {
  color: red;
}

.accordion-body {
  display: flex;
  direction: column;
}

.accordion-chip-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  direction: row;
  padding: 8px 0;
}

.accordion-chip-container > * {
  margin-right: 8px !important;
}

.accordion-chip-popper-container {
  padding: 0px;
}

.accordion-chip-popper-footer {
  display: flex;
  direction: row;
  align-items: center;
  padding: 5px;
}

.accordion-chip-popper-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.accordion-chip-popper-footer-add {
  padding: 2px;
  margin: 0 6px;
}

.accordion-body-container {
  padding: 10px 0;
  padding-bottom: 20px;
  margin-right: 30px;
  max-width: '600px';
  width: 100%;
  word-break: break-all;
}

.accordion-body-container > p {
  margin-top: 0px;
}

.session-metadata {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.session-metadata > * {
  margin-right: 6px;
}

.accordion-body-sub-headers {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.accordion-footer {
  flex-shrink: 0;
  padding-top: 15px;

  box-sizing: border-box;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
}

.row-accordion-wrapper {
  flex-shrink: 0;
  min-width: 100%;

  background-color: #5d60ff0a;
}

.row-accordion {
  min-height: max-content;

  padding: 20px;
  display: flex;
  height: 100%;
  border-left: 3px solid #62a9fb;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.row-button-editor {
  border: none;
  outline: none;
  transition: transform 250ms ease-in-out;
  margin: 0;
  user-select: none !important;
  background: #2b8dff;
  color: #ffffff;
  padding: 0.5em 1em;
  border-radius: 0;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
}

/* ----- Media Query */

@media only screen and (max-width: 1024px) {
  .table-container {
    width: 95vw;
  }
}
